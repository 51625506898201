import React from 'react';
import { AppWrapper } from '../components/styled';

const NotFoundPage = () => (
  <React.Fragment>
    <AppWrapper>
      <h1>NOT FOUND</h1>
      <p>
        You just hit a route that doesn&#39;t exist... the sadness.
      </p>
    </AppWrapper>
  </React.Fragment>
);

export default NotFoundPage;
